import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 40 min. `}<meta itemProp="cookTime" content="PT40M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken broth, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Dry white wine, 1/3 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Leeks, 4-5 cups roughly chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 3 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Flour, 1/4 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cheese, 1 cup shredded (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Parmesan cheese, 1/3 cup grated</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Medium pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Glass pan</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Wash and chop leeks (1 inch length)</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate cheese</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Preheat oven to 400F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bring chicken broth and white wine to a boil.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add leeks. Simmer until tender.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Move leeks to glass pan. Keep the sauce in the pot.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add butter, stir until melted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add flour, stir until smooth and thick.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add pepper, to taste.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add cheddar cheese, stir until melted.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Pour the cheese mixture over the leeks.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Sprinkle with parmesan.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake until top is browned.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      